import React from 'react'

import Layout from '../components/layout'

const SecondPage = () => (
  <Layout>
    <h1>Heading One</h1>
    <p>
      This is a paragraph of text that should be long enough to wrap. If not, we can repeat it over and over again until it does wrap. This is a paragraph of text that should be long enough to wrap. If not, we can repeat it over and over again until it does wrap. This is a paragraph of text that should be long enough to wrap.
    </p>
    <h2>Heading Two</h2>
    <p>
      This is a paragraph of text that should be long enough to wrap. If not, we can repeat it over and over again until it does wrap. This is a paragraph of text that should be long enough to wrap. If not, we can repeat it over and over again until it does wrap. This is a paragraph of text that should be long enough to wrap.
    </p>
    <h3>Heading Three</h3>
    <p>
      This is a paragraph of text that should be long enough to wrap. If not, we can repeat it over and over again until it does wrap. This is a paragraph of text that should be long enough to wrap. If not, we can repeat it over and over again until it does wrap. This is a paragraph of text that should be long enough to wrap.
    </p>
    <h4>Heading Four</h4>
    <p>
      This is a paragraph of text that should be long enough to wrap. If not, we can repeat it over and over again until it does wrap. This is a paragraph of text that should be long enough to wrap. If not, we can repeat it over and over again until it does wrap. This is a paragraph of text that should be long enough to wrap.
    </p>
    <h5>Heading Five</h5>
    <p>
      This is a paragraph of text that should be long enough to wrap. If not, we can repeat it over and over again until it does wrap. This is a paragraph of text that should be long enough to wrap. If not, we can repeat it over and over again until it does wrap. This is a paragraph of text that should be long enough to wrap.
    </p>
    <h6>Heading Six</h6>
    <p>
      This is a paragraph of text that should be long enough to wrap. If not, we can repeat it over and over again until it does wrap. This is a paragraph of text that should be long enough to wrap. If not, we can repeat it over and over again until it does wrap. This is a paragraph of text that should be long enough to wrap.
    </p>
    <hr />
    <p id="anchor">
      <a href="#anchor">This</a> is a link.
    </p>
    <p>
      <em>This is italic text, made with em.</em> <i>This is italic text as well, made with i.</i>
    </p>
    <p>
      <strong>This is bold text, made with strong.</strong> <b>This is bold text as well, made with b.</b>
    </p>
    <p>
      <u>This is underlined text, made with u.</u> <ins>This is inserted text, made with ins.</ins>
    </p>
    <p>
      <strike>This is deleted (strikethrough) text, made with strike.</strike> <s>This is also strikethrough text, but made with s.</s> <del>This is deleted text, made with del.</del>
    </p>
    <p>
      <code>This is computer code, made with code.</code> <tt>This is teletype text, made with tt.</tt>
    </p>
    <p>
      <kbd>This is text the user is supposed to enter in, or keyboard, made with kbd.</kbd> <var>This is a variable, made with var.</var> <samp>This is sample program output, made with samp.</samp>
    </p>
    <p>
      <small>This is small text, made with small.</small> <big>This is big text, made with big.</big>
    </p>
    <p>
      <cite>This is a citation, made with cite.</cite> This is a <q>short quotation</q> in the middle of a sentence, made with q.
    </p>
    <p>
      This sentence ends with a <sup>superscript</sup>. This sentence ends with a <sub>subscript</sub>.
    </p>
    <p>
      This is an abbreviation, made with abbr: <abbr>WWW</abbr>. This is an acronym, made with acronym: <acronym>NATO</acronym>. This is a term that needs a definition, made with dfn: <dfn>Yog-Sothoth</dfn>.
    </p>
    <p>
      This is an unordered list:
    </p>
    <ul>
      <li>Koyaanisqatsi</li>
      <li>Powaqqatsi</li>
      <li>Naqoyqatsi</li>
    </ul>
    <p>
      This is an ordered list:
    </p>
    <ol>
      <li>Larry</li>
      <li>Moe</li>
      <li>Curly</li>
    </ol>
    <p>
      This is a definition list:
    </p>
    <dl>
      <dt>Definition Term</dt>
      <dd>Definition data defining the previous term</dd>
      <dt>Another Definition Term</dt>
      <dd>Definition data defining the previous term</dd>
    </dl>
    <p>
      This is an address with breaks:
    </p>
    <address>
      John Q. Public
      <br />123 Main Street
      <br />Anywhere, ST 12345
    </address>
    <p>
      This is a long quotation inside a blockquote:
    </p>
    <blockquote>
      <p>
        “I love songs about horses, railroads, land, judgement day, family, hard times, whiskey, courtship, marriage, adultery, separation, murder, war, prison, rambling, damnation, home, salvation, death, pride, humor, piety, rebellion, patriotism, larceny, determination, tragedy, rowdiness, heartbreak, and love. And Mother. And God.” ~ Johnny Cash
      </p>
    </blockquote>
    <p>
      This is pre-formatted:
    </p>
    <pre>
    Start in column one.
             Then indent to column ten.
        Then back five spaces.
    </pre>
    <p>
      Here's a table with a border, a caption, colgroups, cols, thead, tfoot, & tbody:
    </p>
    <table border="1">
      <caption>This is a table caption, made with caption</caption>
      <colgroup span="2">
        <col />
        <col />
      </colgroup>
      <colgroup></colgroup>
      <thead>
        <tr>
          <th>Table Header 1</th>
          <th>Table Header 2</th>
          <th>Table Header 3</th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td>Table Footer 1</td>
          <td>Table Footer 2</td>
          <td>Table Footer 3</td>
        </tr>
      </tfoot>
      <tbody>
        <tr>
          <td>TD 1.1</td>
          <td>TD 1.2</td>
          <td>TD 1.3</td>
        </tr>
        <tr>
          <td>TD 2.1</td>
          <td>TD 2.2</td>
          <td>TD 2.3</td>
        </tr>
        <tr>
          <td>TD 3.1</td>
          <td>TD 3.2</td>
          <td>TD 3.3</td>
        </tr>
      </tbody>
    </table>
  </Layout>
)

export default SecondPage
